<template>
  <div id="app" class="flex flex-col" style="height: 100vh;">

    <!-- 登录注册卡片 -->
    <LoginRegisterCard v-if="!isLoggedIn" @loginSuccess="handleLoginSuccess" />
    <!-- 路由视图 -->
    <router-view v-if="isLoggedIn" class="flex-grow overflow-auto"></router-view>

  </div>
</template>

<script>
import LoginRegisterCard from './components/LogSig.vue';

export default {
  name: 'mindbase',
  components: {
    LoginRegisterCard
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },

  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.username = 'User'; 
      this.isLoggedIn = true;
    }
  }
};
</script>

